import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Section from "../components/Section";
import Img from "../components/Img";
import Article from "../components/Article";
import Nav from "../components/Nav";
import H1 from "../components/H1";
import H2 from "../components/H2";
import Paragraph from "../components/Paragraph";
import ParagraphPull from "../components/ParagraphPull";
import Footnote from "../components/Footnote";
import PhotoGallery from "../components/PhotoGallery";

import historyTimeline from "./historyTimeline.json";

const EstatePage = ({ data }) => (
  <Layout>
    <SEO title="History" />
    <Nav />
    <Section>
      <Article isCentered>
        <H1>History</H1>
        <Paragraph isLarge>
          For over 100 years, Oak Hall has stood perched on its hill overlooking Penbobscot Bay.
          Built to stand the tests of time, it has changed remarkably little since it was first
          dreamt up by Ira M. Cobe for his wife Annie.
        </Paragraph>
      </Article>
    </Section>
    <Section>
      <Img
        imgSrc={data.oakHallEngraving.childImageSharp.fluid}
        imgAlt="Engraving of Oak Hall by Steven Noble"
        isTransparent
      />
    </Section>
    <Section>
      <Article>
        <H2>The Story of Oak Hall</H2>
        <Paragraph isLarge>
          This is the story of Oak Hall, the largest, most lavish Colonial Revival house in Maine.
          Built on the site of what was previously Hillside Farm on the western boundary of the
          picturesque Victorian enclave of Bayside Village, Oak Hall has been known by many names
          over the last century: The Cobe Estate, the Pingree Mansion, Cariad, and now Oak Hall.
        </Paragraph>

        <ParagraphPull orientation="RIGHT">
          <Img imgSrc={data.rearPorch.childImageSharp.fluid} imgAlt="Oak Hall rear porch" />
          <Footnote>
            Oak Hall was designed and built in the Colonial Revival style popular at the turn of the
            last century.
          </Footnote>
        </ParagraphPull>

        <Paragraph>
          Perched high on a hill overlooking the islands of Penobscot Bay, Oak Hall has stood vigil
          here for over 100 years. Ground breaking onsite began in 1912 and the home was completed
          and furnished by the summer of 1914, when the original owners, Ira and Annie Cobe, took
          residence. It would go on to serve as a private home for three successive families, until
          being purchased by the current owners, the Brim-DeForests.
        </Paragraph>
        <Paragraph>
          The house is listed on the National Register of Historic Places due to its outstanding
          classical features – the grand, two-storey, colonnaded porticos across the front and rear
          facades; the splayed, marble lintels over windows and doors; the beautifully landscaped
          gardens and trails featuring huge boulders left by an ancient glacier; and inside: the
          white marble floors; the elegant central hall flanked by a double staircase leading to a
          grand, second-floor landing; classic broken pediments capping the large openings off the
          hall; decorative wood paneling; plaster ceilings with raised designs; the dark mahogany
          bookcases and wainscot in the library; the library’s cove ceiling with a quatrefoil
          design; the music room with its magnificent pipe organ and its beautiful ceiling with
          musical instruments incorporated into its raised plaster design
        </Paragraph>
        <Paragraph>
          There are twelve fireplaces, including two on matching North and South sunporches that
          were originally open to the outdoors in summer. And here and there, statues add to the
          classic sense of style.
        </Paragraph>
        <Paragraph>
          Oak Hall has changed little over the past century on account of its rather unique
          construction, meaning that the floor plan today stays true the original designs of
          architect Benjamin H. Marshall.
        </Paragraph>
      </Article>
      <Img
        imgSrc={data.oakHallPostcard.childImageSharp.fluid}
        imgAlt="Postcard: Residence of I. M. Cobe, Northport Camp Grounds, Maine"
        isWide
      />
    </Section>
    <Section>
      <Article>
        <H2>A Home for the Cobes</H2>
        <Paragraph isLarge>
          Born in 1866, Ira Maurice Cobe grew up in Boston where he attended public schools. He
          graduated from Boston University Law School, was admitted to the bar in 1888, and
          practiced law in Boston for several years. Cobe came to Belfast and Bayside during the
          summer of 1891, and there he fell in love with Anne Elizabeth Watts.
        </Paragraph>
        <Paragraph>
          Anne lived with her parents in Belfast, where her father Charles was a stone cutter. Ira
          Cobe married Anne Watts in 1892, and they would return to Belfast and Bayside every
          summer, so Anne could enjoy her family and friends and the cultural programs of Bayside.
        </Paragraph>
        <Paragraph>
          In 1895, the Cobes moved to Chicago where Ira established the firm of Cobe &amp; McKinnon,
          Investments. Similar to a venture capital firm, Cobe &amp; McKinnon helped to finance many
          of the street railways and elevated railroads being built in Chicago at the time. Cobe
          became chairman of the board of Chicago City Railways and served on the boards of Calumet
          and South Chicago Railway and the Hammond, Whiting and East Chicago Railway. He served as
          a director of Chicago Title &amp; Trust Company, the National Bank of the Republic, the
          Financial Corporation of America, and as president of the Assets Realization Company.
          Along with Samuel Insull, Cobe was considered one of the “Big Five” who controlled the
          interlocking directorates of Commonwealth Edison, Peoples Gas Light &amp; Coke Company,
          Chicago Elevated Railroads, and Chicago Surface Lines.
        </Paragraph>
        <ParagraphPull orientation="LEFT">
          <Img imgSrc={data.iraCobe.childImageSharp.fluid} imgAlt="Ira M. Cobe" />
          <Footnote>
            Ira M. Cobe was born 1866 in Boston Mass. to Mark H. and Eva Cobe. He met his bride,
            Annie on a summer trip to Bayside in 1891.
          </Footnote>
        </ParagraphPull>
        <Paragraph>
          Cobe loved automobiles, serving as president of the Chicago Automobile Club. In 1909 and
          1910 he sponsored the Cobe Trophy Race, the first long distance automobile race in the
          Midwest. The first race in 1909 covered a 395-mile circuit in Indiana from Crown Point to
          Cedar Lake to Lowell and finished at the courthouse in Crown Point. Of the twelve starting
          cars, the winner was Swiss American Louis Chevrolet who founded the Chevrolet Motor Car
          Company in 1911. The second Cobe Trophy Race, in 1910, was held at the Indianapolis Motor
          Speedway, and of the fourteen starting cars, Joe Dawson was the winner. This second race
          was the forerunner of the Indianapolis 500.
        </Paragraph>
        <Paragraph>
          In spite of their role in Chicago’s high society, the Cobes returned to Belfast and
          Bayside every summer. In 1899 they built the Queen Anne/Shingle Style cottage which they
          named “Bohemia” on South Shore Road. There they entertained and enjoyed the view of
          Penobscot Bay and the activities of Bayside. But their stable and garage was across the
          road, and Cobe petitioned the town of Northport to be able to move the road, at his own
          expense, to the other side of the stable. The neighbors objected, even building a fence
          across Cobe’s driveway.
        </Paragraph>
        <Paragraph>
          So, in 1909, Ira and Anne Cobe purchased Hillside Farm, sited on 100 acres above their
          Bayside cottage, where they would build and landscape their dream house. The Cobes never
          had children, but from the time the house was completed in 1914, they held lavish parties
          with dancing on the marble portico overlooking the bay and organ recitals by well known
          musicians (the house features a built-in two-storey Aeolian Player Organ).
        </Paragraph>
        <ParagraphPull orientation="RIGHT">
          <Img imgSrc={data.hillsideFarm.childImageSharp.fluid} imgAlt="Hillside Farm" />
          <Footnote>Hillside Farm</Footnote>
        </ParagraphPull>
        <Paragraph>
          In 1917, they hosted an elaborate wedding for Marian Heal, whom the Cobes had helped to
          raise, and Dr. Ansel Lothrop, a Belfast dentist. As a wedding present, the Cobes
          completely furnished the new home of the bride and groom.
        </Paragraph>
        <Paragraph>
          Ira Cobe was instrumental in the founding of the Northport Golf Club; he contributed to
          the Belfast Free Library; and served as president of the Northport Village Corporation.
          Anne Cobe belonged to the Waldo County Hospital Aid Society and the Girls’ Home
          Association.
        </Paragraph>
        <Paragraph>
          Anne died at their winter home at the Hotel Alamac in New York in 1928, and Ira Cobe died
          at Hillside Farm in 1931. Anne and Ira Cobe are buried in a simple Egyptian Revival
          mausoleum with a Tiffany window in Grove Cemetery in Belfast.
        </Paragraph>
        <Paragraph>
          In the depth of the Depression, Cobe left an estate of $1,370,238. He left bequests to the
          Girls’ Home in Belfast; the Waldo County General Hospital; the Belfast Free Library; the
          Children’s Aid Society; and to his brother; a niece; to his secretary; and to Marian Heal
          Lothrop.
        </Paragraph>
      </Article>
    </Section>
    <Section>
      <Article>
        <H2>The Architect: Benjamin H. Marshall</H2>
        <Paragraph isLarge>
          Oak Hall was designed by the architectural firm of Marshall and Fox of Chicago. The
          principal partner, Benjamin H. Marshall, was a colorful society figure, often compared to
          Florence Ziegfeld or Jay Gatsby. At his elaborate parties during the Roaring Twenties, he
          entertained writers, artists, movie stars, Hollywood producers, and the social and
          political elite.
        </Paragraph>
        <ParagraphPull orientation="RIGHT">
          <Img imgSrc={data.benjaminMarshall.childImageSharp.fluid} imgAlt="Benjamin H. Marshall" />
          <Footnote>
            Benjamin H. Marshall was a sought after Chicago society architect. When it came time to
            design Oak Hall, Marshall was Cobe’s natural choice.
          </Footnote>
        </ParagraphPull>
        <Paragraph>
          Growing up in Chicago, Marshall had a privileged childhood and attended the private
          Harvard School in Kenwood. His classmates were Farwells, Florsheims, Pullmans, and Drakes.
          He would later build the two great society hotels for the Drake brothers – the Drake and
          the Blackstone. Marshall was a handsome, charming, stylishly dressed, Chicago celebrity.
        </Paragraph>
        <Paragraph>
          The fabulous buildings erected for the Chicago World’s Fair of 1893 so impressed young
          Marshall that he decided to become an architect. He apprenticed with the firm of Marble
          and Wilson, established his own firm in 1902, and, with engineer Charles Fox, created the
          partnership of Marshall &amp; Fox in 1905. With Fox as construction engineer and project
          manager, Marshall designed buildings with the Italian Renaissance grandeur of the Drake
          Hotel, the pink fantasy of the Edgewater Beach Apartments on Lake Shore Drive, the
          Parisian splendor of the apartments at 1550 North State Parkway, the Second Empire panache
          of the Blackstone Hotel, the Mediterranean Revival elegance of the South Shore County Club
          — now the South Shore Culture Center, and stylish Classical Revival theaters — the
          Blackstone Theater in Chicago, the Maxine Elliott Theater in New York, and the Fitzgerald
          Theater in St. Paul, Minnesota.
        </Paragraph>
        <Paragraph>
          Oak Hall has many elaborate interior details that were trademarks of Marshall’s design —
          the large central hall with the grand double staircase rising to the elegant second floor
          landing; the great, carved entrances to rooms off the central hall; the decorative wood
          paneling; the plaster ceilings with raised designs, the Italian marble floors, the many
          fireplaces, the statues.
        </Paragraph>
      </Article>
      <Img
        imgSrc={data.theDrake.childImageSharp.fluid}
        imgAlt="Postcard: The Drake, Lake Shore Drive and Upper Michigan Avenue, Chicago"
        isWide
      />
      <Article>
        <Paragraph>
          As Marshall and Fox were completing the Cobe Estate in 1914, they began construction of an
          Italian Renaissance mansion for power company magnate Samuel Insull, just outside of
          Chicago. It would have a retractable roof over its center hall, so that in summer the hall
          could resemble an Italian center courtyard.
        </Paragraph>
        <Paragraph>
          Marshall was married to Elizabeth Walton Marshall, and they had three children. Charles
          Fox retired in 1924, just two years before his death. Marshall continued to operate alone
          until the Great Depression bankrupted him. He and his wife moved into the Drake Hotel
          where Marshall helped manage the hotel and designed some of the hotel’s famous interiors.
          Marshall died in 1944.
        </Paragraph>
      </Article>
    </Section>
    <Section>
      <Article>
        <H2>The Landscape Architect: Warren H. Manning</H2>
        <Paragraph isLarge>
          Before Benjamin Marshall began designing the lavish Colonial Revival mansion for Ira Cobe,
          Cobe hired Warren Manning, a Boston landscape architect to lay out the roads, trails,
          lawns, and gardens to emphasize the vistas of Hillside Farm.
        </Paragraph>
      </Article>
      <Img
        imgSrc={data.historicNorthView.childImageSharp.fluid}
        imgAlt="Oak Hall north view"
        isWide
      />
      <Article>
        <Paragraph>
          Manning, born in 1870, grew up in Reading, Massachusetts, where his father owned the
          successful Reading Nursery. From 1888 to 1896, Manning worked as planting assistant for
          the great landscape architect Frederick Law Olmsted. He worked on many of Olmsted’s
          important projects including the grounds of the U. S. Capitol and Rock Creek Park in
          Washington, D. C.; George Vanderbilt’s summer home in Bar Harbor; the Chicago World’s Fair
          of 1893; and Biltmore, the estate of George Vanderbilt in Asheville, North Carolina. In
          1896, Manning formed his own company, designing the grounds of small houses, large
          estates, college campuses, city centers, and parks. Manning was instrumental in forming
          the American Society of Landscape Architects, which established professional standards for
          landscape architects. In 1911, he was designing the Megunticook Golf Club in Camden and
          Aldemere Farm, the estate of Albert H. Chatfield, also in Camden, when Ira Cobe hired him.
        </Paragraph>
        <ParagraphPull orientation="LEFT">
          <Img imgSrc={data.warrenManning.childImageSharp.fluid} imgAlt="Warren H. Manning" />
          <Footnote>
            Warren H. Manning began his career working for Frederick Law Olmsted but went on to
            become a notable landscape architect in his own right.
          </Footnote>
        </ParagraphPull>
        <Paragraph>
          Manning’s philosophy was to use existing natural resources to draw attention to the
          distinctive characteristics of a place. He had an unusual ability to emphasize both
          intimate details and the broad patterns found at a site. His style was sometimes called
          “Picturesque,” indicating a romantic view of the past.
        </Paragraph>
        <Paragraph>
          At the Cobe’s Hillside Farm, Manning took advantage of the boulders on the glacial hill to
          build stone walls along the drive, a retaining wall for the formal garden, and some giant
          boulders were left in place as sentinels from the past. He wrapped the hill in a winding
          drive that gives a commanding view of Penobscot Bay and exaggerates the height of the
          mansion’s columns as one approaches from below. Using the rural meadow to create expansive
          lawns, Manning emphasized the far-off views of mountains and ocean. He used massed
          plantings of native trees and shrubs – spruce, firs, arbor vitae, rhododendron, viburnum,
          spiraea, and honeysuckle – to provide definition and privacy. And the formal gardens close
          to the house provided beautiful detail and color.
        </Paragraph>
        <Paragraph>
          Manning went on to design several national parks and to write “A National Plan for
          America” promoting planning on a national scale to take advantage of the natural landscape
          when designing towns, cities, manufacturing centers, transportation corridors, and even
          state boundaries.
        </Paragraph>
      </Article>
    </Section>
    <Section>
      <Article>
        <H2>Oak Hall as it Was</H2>
        <Paragraph isLarge>
          Photographs from 1912 until 1967, sourced from the Oak Hall archive and courtesy of
           the Penobscot Marine Museum.
        </Paragraph>
      </Article>
      <PhotoGallery items={data.gallery.edges}></PhotoGallery>
    </Section>
    <Section>
      <Article>
        <H2>The Families</H2>
        <Paragraph>
          <strong>The Cobes</strong> designed and built Oak Hall on the site of Hillside Farm. Ira and 
          Annie lived on the estate from 1914 until Ira’s passing in 1931. They had no children.
        </Paragraph>
        <Paragraph>
          <strong>The Pingrees</strong> owned the Cobe Estate from 1936 to 1978. The house had stood
          empty for five years after Cobe’s death. George Elmer Pingree graduated from Phillips
          Academy and Dartmouth. He was the president of International Standard Electric Corporation
          and vice president of International Telephone and Telegraph Corporation. He and his family
          lived in Brussels for many years where he served on the boards of foreign companies.
          During World War I, Pingree engaged in special work for the British War Office, and he was
          awarded the Order of the Crown by Belgium. He was married to Gertrude Maxine. 
          The Pingrees filled in the reflecting pool on the east side of the house and replaced 
          the formal sunken garden just beyond with a swimming pool.
        </Paragraph>
        <Paragraph>
          <strong>The Evans</strong> owned the mansion from 1978 to 2001. John K. Evans emigrated
          from Wales to Canada and then to New York. While waiting tables in one of Dutch Schultz’s
          speakeasies, he met the vice president of Shell Oil who persuaded him to work for Shell.
          Evans worked for Shell Oil for thirty years and then left to form his own company –
          Independent Fuel Marketers of America. He tried for many years to build an oil refinery on
          the East Coast, and when his efforts failed, he went to Hawaii where he created the
          successful Pacific Resources, Inc. Evans worked as an oil trader and soon was on a first
          name basis with international business and political leaders, including presidents. 
          After John passed away in 1991, his wife Jean and her children retained the estate for 
          another decade. The Evanses took down the stone wall near the property entrance, decreased 
          the property from 100 acres to fifty, and created an Asian-style garden in the teardrop-shaped 
          island opposite the main entrance.
        </Paragraph>
        <Paragraph>
          <strong>The Reids</strong> owned the Cobe Estate from 2001 to 2020. Gerald Reid was a
          tobacco and liquor marketing executive. He was a brand manager for Brown &amp; Williamson
          Tobacco Company, and then led product development for Jose Cuervo International, the
          number one tequila producer in the world. In 2014, Reid became the Liquor and Lottery
          Chief for Maine. The Reids filled in the swimming pool and removed much of the colorful
          perennials and flowering shrubs. His wife Dorothy did much detailed restoration work
          throughout the property.
        </Paragraph>
        <Paragraph>
          <strong>The Brim-DeForests</strong> purchased the Cobe Estate in 2020 and has embarked on
          restoration efforts throughout the property. Brady is a technology executive, formerly of
          California, and has two children, Hypatia and Huxley, with partner Jessica Cole. The
          Brim-DeForests have a passion for preservation of historical properties, including Brady's
          family seat in Scotland, Balvaird Castle, which he is presently restoring.
        </Paragraph>
      </Article>
    </Section>
    <Section>
      <Article>
        <H2>109 Years</H2>
        <OrderedList>
          {historyTimeline.map(event => (
            <OrderedItem>
              <OrderBy>{event.year}</OrderBy>
              <div>
                <Paragraph>
                  <strong>{event.title}</strong>
                </Paragraph>
                <Paragraph>{event.description}</Paragraph>
              </div>
            </OrderedItem>
          ))}
        </OrderedList>
      </Article>
    </Section>
  </Layout>
);

const OrderedList = styled.ol``;

const OrderedItem = styled.li`
  display: grid;
  grid-template-columns: max-content auto;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  &:last-child {
    border-bottom: none;
  }
`;

const OrderBy = styled.div`
  float: left;
  margin-right: 16px;
  height: 100%;
`;

export const query = graphql`
  query {
    gallery: allFile(filter: { absolutePath: { regex: "/history/gallery/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1440) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    oakHallEngraving: file(absolutePath: {regex: "/\\/history\\/oak-hall-engraving\\.png/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rearPorch: file(absolutePath: {regex: "/\\/history\\/rear-porch\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 840) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    oakHallPostcard: file(absolutePath: {regex: "/\\/history\\/oak-hall-postcard\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iraCobe: file(absolutePath: {regex: "/\\/history\\/ira-cobe\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 840) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hillsideFarm: file(absolutePath: {regex: "/\\/history\\/hillside-farm\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 840) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    benjaminMarshall: file(absolutePath: {regex: "/\\/history\\/benjamin-marshall\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 840) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    theDrake: file(absolutePath: {regex: "/\\/history\\/the-drake\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    historicNorthView: file(absolutePath: {regex: "/\\/history\\/historic-north-view\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    warrenManning: file(absolutePath: {regex: "/\\/history\\/warren-manning\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 840) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default EstatePage;
